/*
 * This file is part of the IoT Management Service
 * Copyright 2019 Canonical Ltd.
 *
 * This program is free software: you can redistribute it and/or modify it
 * under the terms of the GNU Affero General Public License version 3, as
 * published by the Free Software Foundation.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranties of MERCHANTABILITY,
 * SATISFACTORY QUALITY, or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

export default {    
    'account-name-desc':        'Name of the company',
    'account-code-desc':        'Account ID from the Ubuntu Store',
    'accounts':                 'Accounts',
    'action':                   'Action',
    'actions':                  'Actions',
    'active':                   'Active',
    'address':                  'Address',
    'brand':                    'Brand',
    'brand-desc':               'Brand ID from the model assertion',
    'cancel':                   'Cancel',
    'close':                    'Close',
    'code':                     'Code',
    'confirm-snap-install':     'Enter the snap to install',
    'confirm-user-delete':      'Confirm user deletion',
    'copy-device-key':          'Copy device public key to clipboard',
    'country':                  'Country code',
    'country-desc':             'SSL certificate country code',
    'created':                  'Created',
    'current-time':             'Current Time',
    'delete-user':              'Delete User',
    'description':              'Description',
    'device-data':              'Device-specific Data',
    'device-detail':            'Device Details',
    'device-name-desc':         'Unique name of the device',
    'devices':                  'Devices',
    'devices-connected':        'Connected Devices',
    'devices-excluded':         'Not in Group',
    'disabled':                 'Disabled',
    'download-file':            'Download File',
    'edit-account':             'Edit Account',
    'edit-device':              'Edit Device Registration',
    'edit-group':               'Edit Device Group',
    'edit-user':                'Edit User',
    'email':                    'Email',
    'enrolled':                 'Enrolled',
    'error-auth-device':        'Invalid device',
    'error-no-auth':            'You do not have permissions to access this site. Please login.',
    'error-no-permissions':     'You do not have permissions to access this page',
    'error-account-invalid':    'Invalid Account',
    'error-user-invalid':       'Invalid User',
    'firmware-version':         'Firmware Version',
    'group-desc':               'Name of a device group',
    'groups':                   'Groups',
    'hardware-version':         'Hardware Version',
    'id':                       'ID',
    'identity':                 'Identity',
    'info':                     'Info',
    'install-on-device':        'Install on device',
    'install-snap':             'Install a snap',
    'installed-snaps':          'Installed Snaps',
    'kernel-version':           'Kernel Version',
    'key':                      'Key',
    'last-update':              'Last Update',
    'loading':                  'Loading...',
    'model':                    'Model',
    'model-desc':               'Model name from the model assertion',
    'modified':                 'Modified',
    'name':                     'Name',
    'new-account':              'Create New Account',
    'new-device':               'New Device Registration',
    'new-group':                'New Device Group',
    'new-user':                 'Create New User',
    'no-accounts':              'No accounts registered',
    'no-actions':               'No actions found',
    'no-devices':               'No devices registered',
    'no-devices-connected':     'No devices connected',
    'no-groups':                'No groups registered',
    'no-users':                 'No users registered',
    'ok':                       'OK',
    'on-classic':               'On Classic',
    'os-version':               'OS Version',
    'refresh':                  'Refresh',
    'refresh-snap-list':        'Update installed snaps',
    'reg-date':                 'Registration Date',
    'register':                 'Register',
    'register-desc':            'Devices must be registered with the Identity Service to allow them to connect securely to the cloud services',
    'register-devices':         'Registered Devices',
    'registered':               'Registered',
    'remove':                   'Remove',
    'result':                   'Result',
    'role':                     'Role',
    'save':                     'Save',
    'search-store':             'search store for snap',
    'serial':                   'Serial Number',
    'serial-desc':              'Serial Number from the serial assertion',
    'series':                   'Series',
    'settings':                 'Settings',
    'serial-number':            'Serial Number',
    'site-description':         'Manage Ubuntu devices connected via the MQTT protocol.',
    'snap':                     'Snap',
    'snap-store':               'Snap Store',
    'snaps':                    'Snaps',
    'software-version':         'Software Version',
    'status':                   'Status',
    'summary':                  'Summary',
    'system-info':              'System Information',
    'utc-offset':               'UTC Offset',
    'timezone':                 'Timezone',
    'title':                    'Device Management Service',
    'toggle-status':            'Enable/Disable',
    'update':                   'Update',
    'uptime':                   'Uptime',
    'user-email-desc':          'Email address of the user',
    'user-not-found':           'You do not have permissions to access this site',
    'user-name-desc':           'Full name of the user',
    'user-username-desc':       'Ubuntu SSO name of the user',
    'username':                 'Username',
    'users':                    'Users',
    'version':                  'Version',
    'view-settings':            'View configuration',
    'waiting':                  'Waiting',
    'yes':                      'Yes',
}
