/*
 * This file is part of the IoT Management Service
 * Copyright 2019 Canonical Ltd.
 *
 * This program is free software: you can redistribute it and/or modify it
 * under the terms of the GNU Affero General Public License version 3, as
 * published by the Free Software Foundation.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranties of MERCHANTABILITY,
 * SATISFACTORY QUALITY, or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, {Component} from 'react';
import api from '../models/api';
import {T} from './Utils';


class Footer extends Component {

  constructor(props) {
      super(props)
      this.state = {version: ''};

      this.getVersion();
  }

  getVersion() {
    api.version().then(response => {
        this.setState({version: response.data.version})
    })
  }

  render() {
    return (
      <footer className="spacer">
        <div>
          <p><small>{T('version')}: {this.state.version}</small></p>
        </div>
      </footer>
    );
  }
}

export default Footer;
